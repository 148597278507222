import { jsx as t } from "react/jsx-runtime";
import { styled as i } from "@linaria/react";
import e from "clsx";
import { forwardRef as f, memo as n } from "react";
/* empty css                             */
const c = /* @__PURE__ */ i("div")({
  name: "STypographyCopy",
  class: "s1d3qld9",
  propsAsIs: !1
}), d = f(function({
  mqNode: o = "div",
  mqVariant: p = "body1",
  mqColor: r = "textPrimary",
  className: y,
  children: s,
  ...a
}, m) {
  return /* @__PURE__ */ t(c, {
    ...a,
    ref: m,
    as: o,
    className: e(y, p, r),
    children: s
  });
}), q = n(d);
export {
  q as TypographyCopy
};
