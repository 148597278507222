import { jsx as a } from "react/jsx-runtime";
import { styled as i } from "@linaria/react";
import e from "clsx";
import { forwardRef as p } from "react";
/* empty css                                */
const h = /* @__PURE__ */ i("h1")({
  name: "SHeading",
  class: "sekgua9",
  propsAsIs: !1
}), f = "c1n26pyx", l = "c1w6igcu", m = "cp2oios", H = "csjhcr4", g = "c1v9wh3d", C = "c1t3wb8y", _ = "c1n2s0oc", d = "c1f2wjf7", j = p(function({
  mqNode: t = "h1",
  mqVariant: o,
  className: c,
  children: n,
  ...S
}, r) {
  const s = o || t;
  return /* @__PURE__ */ a(h, {
    ...S,
    ref: r,
    as: t,
    className: e(c, {
      [f]: s === "h1",
      [l]: s === "h2",
      [m]: s === "h3",
      [H]: s === "h4",
      [g]: s === "h5",
      [C]: s === "h6",
      [_]: s === "subtitle1",
      [d]: s === "subtitle2"
    }),
    children: n
  });
});
export {
  j as TypographyHeading
};
